@import "./colors";
@import "./typography";
@import "./spacing";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: var(--colors-text-main);
}

body {
  min-height: 100vh;
  height: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  background: var(--colors-background);
}

button {
  cursor: pointer;
  border: none;

  font-size: 16px;
  font-weight: 700;
  line-height: 23px;
}

a {
  color: var(--colors-text-link);
  text-decoration: none;
}

.error {
  color: red;
}