.appContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: scroll;
  min-height: 100vh;

  width: 800px;
  border-radius: 60px 0px 0px 0px;
  background-color: var(--colors-white);
  padding: 82px 80px;

  @media (min-width: 1441px) {
    max-width: 1024px;
  }
  @media (max-width: 798px) {
    padding: 40px 40px;
    border-radius: 0px 0px 0px 0px;
  }
}