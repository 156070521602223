@import "./colors.scss";

@font-face {
  font-family: "SC Portugal-400";
  src: url("../assets/fonts/SC\ Portugal.ttf");
}

@font-face {
  font-family: "Zen Kaku Gothic New-400";
  src: url("../assets/fonts/ZenKakuGothicNew-Regular.ttf");
}

@font-face {
  font-family: "Zen Kaku Gothic New-500";
  src: url("../assets/fonts/ZenKakuGothicNew-Medium.ttf");
}

@font-face {
  font-family: "Zen Kaku Gothic New-700";
  src: url("../assets/fonts/ZenKakuGothicNew-Bold.ttf");
}

h1 {
  font-family: "SC Portugal-400", sans-serif;
  font-size: 42px;

}

.fire {
  background: var(--colors-gradient2);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.water {
  background: var(--colors-gradient1);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h2 {
  font-family: "SC Portugal-400", sans-serif;
  font-size: 32px;
}

p {
  font-family: "Zen Kaku Gothic New-400", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: var(--colors-text-main);
}

.body-2 {
  font-family: "Zen Kaku Gothic New-500", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
}

.body-1 {
  font-family: "Zen Kaku Gothic New-400", sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: 120%; /* 19.2px */
}

input,
textarea,
select {
  font-family: "Zen Kaku Gothic New-400", sans-serif;
  font-style: normal;
  line-height: 14px;
  color: var(--colors-text-main);
}

input,
textarea,
select {
  font-size: 12px;
}
