:root {
  --colors-background: #050C1B;

  --colors-text-main: #000000;
  --colors-text-secondary: #ffffff;
  --colors-text-link: #0995fa;

  --colors-base-mirage: #222c3b;
  --colors-base-mirage-light: #323f53;

  --colors-gradient1: linear-gradient(141deg, #08d3ff 28.81%, #08ff3f 112.02%);
  --colors-gradient2: linear-gradient(136.23deg, #FF560D 3.19%, #F5DF1B 105.43%);

  --colors-border: #cecece;
  --colors-lines: #d9d9d9;

  --colors-white: #fff;
  --colors-black: #000;

  --colors-error: #ea2929
}
