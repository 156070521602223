.Button {
	border-radius: 20px;
	background: var(--colors-base-mirage);
	padding: var(--spacing-sm);

	color: var(--colors-white);

	&:hover:not(.disabled) {
		cursor: pointer;
		background: var(--colors-base-mirage-light);
	}
}
