
.page {
  max-width: 1440px;
  margin: 0 auto;
  position: relative;

  p, h1 {
    color: white;
  }
}

.title {
  position: relative;
  font-family: 'SC Portugal-400';
  font-size: 62px;
  font-weight: 400;
  line-height: 68px;
  letter-spacing: 0em;
  text-align: left;
}

.page_title {
  position: relative;
  z-index: 1200;
  padding-top: 210px;
  padding-left: 73px;
  width: 1061px;
}

.bg1 {
  position: absolute;
  z-index: 1100;
  top: 0px;
  right: 0px;
  filter: brightness(50%);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.bg2 {
  position: absolute;
  z-index: 0;
  left: -820px;
  top: 200px;
  filter: brightness(50%);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.slide {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.mission {
  position: relative;
  z-index: 1;
  margin-top: 132px;
  width: 100%;
}

.mission_image {
  z-index: 1;
  position: relative;
  border-radius: 90px;
  width: 100%;
  height: 470px;
  background: url('../../assets/images/mission.png') no-repeat center center;
  margin-bottom: 5px;
  background-size: cover;
}

.mission_text {
  border-radius: 80px;
  display: flex;
  padding: 90px 73px;
  justify-content: flex-start;
  align-items: flex-start;
  background: white;
  gap: 176px;

  h2 {
    width: 315px;
  }

  p {
    color: var(--colors-text-main);
    font-size: 24px;
  }
}

.text_title {
  position: relative;
  z-index: 1500;
  font-size: 62px;
  font-weight: 400;
  line-height: 68px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--colors-base-mirage);
}

.values {
  margin-top: 120px;
  margin-left: 76px;
}

.cards {
  display: flex;
  gap: 9px;
  position: relative;

}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  width: 418px;
  height: 612px;
  gap: 85px;
  text-align: center;
  border: 1px solid var(--colors-base-mirage);
  border-radius: 35px;
  transition: 0.2s ease-in;

  &:hover {
    filter: brightness(120%);
  }
}

.card_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.card_title {
  width: 100%;
  text-align: center;
  font-family: 'Zen Kaku Gothic New-500';
  font-size: 28px;
  margin-bottom: 20px;
  max-width: 241px;
}

.card_description {
  font-family: 'Zen Kaku Gothic New-400';
  font-size: 16px;
  text-align: center;
  color: #9EA0A4 !important;
  max-width: 354px;
}

.approach {
  margin-top: 125px;
  border-radius: 80px;
  display: flex;
  flex-direction: column;
  padding: 90px 73px;
  justify-content: flex-start;
  align-items: flex-start;
  background: white;
  gap: 60px;

  p {
    color: var(--colors-text-main);
  }
}

.approach_description {
  display: flex;
  justify-content: flex-start;
  width: 90%;

  > div {
    flex: 30%;
  }

  p {
    max-width: 684px;
    font-size: 24px;
  }
}

.team {
  margin-top: 120px;

  p {
    color: var(--colors-white);
  }
}

.team_text {
  padding: 0px 73px;
  display: flex;
  gap: 128px;
}

.team_description {
  color: #9EA0A4 !important;
  font-size: 24px;
}

.contacts_image {
  margin-top: 60px;
  z-index: 1;
  position: relative;
  border-radius: 90px;
  width: 100%;
  height: 470px;
  background: url('../../assets/images/contacts.png') no-repeat center center;
  margin-bottom: 5px;
  background-size: cover;
}

.contacts {
  position: relative;
  margin-top: 5px;
  background: white;
  display: flex;
  flex-direction: column;
  padding: 90px 0px 90px 73px;
  justify-content: flex-start;
  align-items: flex-start;
  background: white;
  gap: 60px;
  border-radius: 90px 90px 0 0;
  overflow: hidden;

  p {
    color: var(--colors-text-main);
  }
}

.contacts_description {
  width: 100%;

  p {
    font-size: 24px;
  }
}

.contacts_bg {
  position: absolute;
  right: 0;
  bottom: 0;
}