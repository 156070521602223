.header {
  z-index: 2000;

  position: fixed;
  top: 0px;
  left: 0;
  height: 76px;
  width: 100vw;
  background: var(--colors-background);
  display: flex;
  align-items: center;

  p {
    color: var(--colors-text-secondary);
  }
}

.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0px 73px;
  padding-top: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.logo {
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo_title {
  text-transform: uppercase;
  font-family: "SC Portugal-400", sans-serif;
}

.action {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}