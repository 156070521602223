.background {
  position: fixed;
  left: 0;
  top: 0;
  background: url("../../../assets/images/BG.svg") no-repeat;
  background-size: cover;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.content {
  position: relative;
  z-index: 10;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: flex-end;
}
