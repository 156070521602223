.navbar {
  position: fixed;
  z-index: 100;
  left: 40px;
  bottom: 40px;
  height: 174px;
  width: 376px;
}

.navbar_list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.navbar_link {
  font-family: "Zen Kaku Gothic New 400", sans-serif;
  color: var(--colors-white);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  opacity: 0.5;
}

.navbar_node {
  list-style: none;
}

.active {
  opacity: 1;
  border-bottom: 1px solid var(--colors-white);
  padding-bottom: 4px;
  margin-bottom: 6px;
}
